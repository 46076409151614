.animated-light{
    position: absolute;
    top: 0;
    right: calc(60vh / 2.4);
    width: calc(90vh);
    height: calc(90vh);
    transform: translate(50%, -45%);
    background: #fff;
    background: radial-gradient(hsla(0,0%,100%,.4) 0,hsla(0,0%,100%,0) 60%);
    z-index: 2;
    display: block;
    border-radius: 50%;
    transition: all 0.1s ease;
    transform: scale(0);
    opacity: 0;
    animation: light-animation 1.5s ease 1s 1 forwards;
}

@keyframes light-animation{
    0%  {transform: translate(50%, -45%) scale(0.5);opacity: 0;}
    100%{transform: translate(50%, -45%) scale(1);opacity: 1;}
}

@keyframes top-hamburger-open{
    0%      {transform: translateY(4px)}
    50%     {transform: translateY(14px)}
    100%    {transform: translateY(14px) rotate(45deg)}
}

@keyframes bottom-hamburger-open{
    0%      {transform: translateY(24px)}
    50%     {transform: translateY(14px)}
    100%    {transform: translateY(14px) rotate(-45deg)}
}


@keyframes top-hamburger-close{
    0%      {transform: translateY(14px) rotate(45deg)}
    50%     {transform: translateY(14px)}
    100%    {transform: translateY(4px)}
}

@keyframes bottom-hamburger-close{
    0%      {transform: translateY(14px) rotate(-45deg)}
    50%     {transform: translateY(14px)}
    100%    {transform: translateY(24px)}
}


/*
animation-name
Specifies the name of the @keyframes at-rule describing the animation’s keyframes.
animation-duration
Configures the length of time that an animation should take to complete one cycle.
animation-timing-function
Configures the timing of the animation; that is, how the animation transitions through keyframes, by establishing acceleration curves.
animation-delay
Configures the delay between the time the element is loaded and the beginning of the animation sequence.
animation-iteration-count
Configures the number of times the animation should repeat; you can specify infinite to repeat the animation indefinitely.
animation-direction
Configures whether or not the animation should alternate direction on each run through the sequence or reset to the start point and repeat itself.
animation-fill-mode
Configures what values are applied by the animation before and after it is executing.
animation-play-state
Lets you pause and resume the animation sequence.
*/