$nav-height: 120px;
$nav-height-mini: 80px;

body{
    font-family: $font-sans;
    background: #20202b;
    box-sizing: border-box;
    @include font-sizer(18px, 16px);
    *{box-sizing: border-box;}
}

a{
    text-decoration: none;
    position: relative;
    z-index: 1;
    transition: color 0.2s ease;
    padding: 2px;

    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;

    &:hover::before{
        height: 100%;
    }
    &::before{
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 1px;
        transition: height 0.2s ease;
        border-radius: 2px;
        z-index: -1;
    }
    &.logo::before{display: none;}
    svg{width: 100%;height: 100%;}
}

nav{
    height: $nav-height;
    position: absolute;
    top: 0px;
    z-index: 3;
    background: #242430;
    box-shadow: 0 2px 5px rgba(0,0,0,0.3);
    width: 100%;
    color:#fff;

    .nav-inner{
        max-width: 128rem;
        width: 100%;
        display: flex;
        margin: 0 auto;
        height: 100%;
        align-items: center;
        justify-content: center;
        @media (max-width: $break){
            flex-wrap: wrap;
        }
    }

    .nav-center{
        flex: 0 1 50%;
        align-items: center;
        display: flex;
        justify-content: flex-end;
    }

    .nav-right{
        display: flex;
        flex: 0 1 50%;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        align-content: center;
        > div{
            flex: 0 1 auto;
            &:first-of-type{margin-right: 15px;}
        }
        .email{
            flex: 0 1 100%;
            margin-top: 10px;
        }
    }

    a{
        margin-right: 20px;
        color: #FF7A64;
        &:hover{color:#fff;}
        &::before{background-color: #FF7A64;}
        &:last-of-type{margin-right:0px;}
    }

    img{height: 60px;width: auto;}

    a.logo{
        height: 100%;
        max-width: 145px;
        fill: #fff;
        flex: 0 0 auto;
        padding: 10px 0;
        cursor: pointer;
        transition: fill 0.2s ease;
        margin: 0 25px;
        &:hover{fill:#FF7A64;}
    }

    .hamburger-menu{display: none}

    @media(max-width: $break){

        flex-wrap: wrap;
        height: $nav-height-mini;
        justify-content: initial;

        &.open{
            .nav-center{transform: scale(1);}
            .nav-right{opacity: 1;}
            .nav-center,.nav-right{visibility: visible; pointer-events: all}
            .nav-center a{opacity: 1;}
            .hamburger-menu{
                &::before{animation: top-hamburger-open 0.5s ease 1 forwards;}
                .line{opacity: 0;transition: opacity 0.25s ease;}
                &::after{animation: bottom-hamburger-open 0.5s ease 1 forwards;}
            }
        }

        &.closed{
            .hamburger-menu{
                &::before{animation: top-hamburger-close 0.5s ease 1 forwards;}
                &::after{animation: bottom-hamburger-close 0.5s ease 1 forwards;}
            }
        }

        .nav-center{
            flex: 0 1 100%;
            background: #fff;
            padding: 40px 0;
            display: flex;
            justify-content: flex-start;
            height: 100vh;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: center;
            font-size: 35px;

            transform: scaleY(0);
            transform-origin: top;
            visibility: hidden;
            pointer-events: none;
            transition: transform 0.4s ease;
            a{opacity: 0;}
            a:nth-of-type(1){transition: opacity 0.2s ease 0.4s}
            a:nth-of-type(2){transition: opacity 0.2s ease 0.6s}
            a:nth-of-type(3){transition: opacity 0.2s ease 0.8s}
            a:nth-of-type(4){transition: opacity 0.2s ease 1s}
            
        }
        a{margin: 0 0 20px 0;}
        .logo{order:-2; margin: 0}
        .nav-right{
            position: absolute;
            width: 100%;
            background: #fff;
            color: #4a4a4a;
            display: flex;
            position: absolute;
            top: 60vh;
            justify-content: space-evenly;
            text-align: center;

            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            transition: opacity 0.2s ease 1.2s;
        }

        .hamburger-menu{
            display: block;
            order: -1;
            width: 30px;
            height: 30px;
            position: absolute;
            right: 15px;
            cursor: pointer;
            &::before{transform: translateY(4px)}
            .line{transform: translateY(14px);transition: opacity 0.25s ease 0.25s;}
            &::after{transform: translateY(24px);}
            &::after,&::before{content:" "}
            &::after,&::before,.line{
                width: 100%;
                display: block;
                height: 2px;
                background: #fff;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

main{
    margin-top: $nav-height;
    margin-bottom: 73px;
    position: relative;
    z-index: 1;
    background: #20202b;
    @media(max-width: $break){
        margin-top: $nav-height-mini;
    }
}

.hero{
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    z-index: 1;
    justify-content: center;
    align-items: center;
    color: #fff;
    flex-wrap: wrap;
    text-align: center;
    position: relative;
    > *:not(.bg){
        z-index: 2;
        position: relative;
    }
    .bg{
        background: url('/images/view1.jpg') bottom/cover no-repeat;
        position: absolute;
        top: -($nav-height / 4);
        left: 0;
        width: 100%;
        height: calc(100% + #{$nav-height / 4});
        z-index: 0;
        opacity: 0.4;
        will-change: transform;
        backface-visibility: hidden;
        perspective: 1000;
        @media(max-width: $break){
            top: -$nav-height-mini;
            height: calc(100% + #{$nav-height-mini});
        }
    }
    h1{
        font-weight: 300;
        letter-spacing: 1px;
        font-family: $font-serif;
        @include font-sizer(55px, 30px);
    }
}

h3{
    display: flex;
    justify-content: space-between;
    color: #717171;
    font-weight: 300;
    @include font-sizer(18px, 16px);
}

.body-wrap{
    background: #fff;
    position: relative;
    z-index: 2;
    padding: 2rem 0 0 0;
    box-shadow: inset 0 3px 5px rgba(0,0,0,0.2);

    h4{
        margin: 4rem 0;
        text-align: center;
        color: #1d1d1d;
        text-shadow: 1px 2px 0 #fe7a64;
    }
}

.row{
    max-width: 90rem;
    margin: 0 auto;
    position: relative;
    &.large{max-width: none;}
    &.coloured{background: #242430;h4{color:#fff;}}
    &.sub-hero{
        height: 60vh;
        position: relative;
        background: #0c4a80;
        overflow: hidden;
        .inner-row{
            height: 100%;
            padding: 0;
        }
        h1{
            font-family: $font-serif;
            text-transform: none;
            @include font-sizer(40px, 25px);
            margin-bottom: 1rem;
            font-weight: 300;
        }
        h2{@include font-sizer(20px, 18px);font-weight: normal}
        .lhs{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            @media(max-width: $break){
                position: relative;
                z-index: 2;
                text-shadow: 1px 1px 3px rgba(0,0,0,0.3);
                flex: 0 1 auto;
                max-width: 300px;
                h2{line-height: 1.4;}
            }
        }
        @media(max-width: $break){
            .rhs{
                position: absolute;
                z-index: 1;
                transform: translateX(25%);
                .electrician-vector{opacity: 0.7;}
            }
        }
    }

    &.service-area{
        background: url('/images/map_huge_2.jpg') center / cover no-repeat;
        h4{margin: 1rem}
        a{color:#fff;&:hover{color: $dark}&::before{background:#fff}}
        .inner-row{
            text-align: center;
            flex-direction: column;
            padding: 20rem 0;
        }
    }

    &.established{
        position: relative;
        overflow: hidden;
        //background: url('/images/LunaPark5.jpg') center / cover no-repeat;
        .inner-row{
            padding: 20rem 0;
            text-align: center;
            flex-direction: column;
            position: relative;
            z-index: 2;
        }
    }

    .inner-row{
        max-width: 90rem;
        margin: 0 auto;
        display: flex;
        color: #fff;
        align-items: center;
        justify-content: center;
        padding: 2rem 0;
    }
    h4{@include font-sizer(40px, 30px);}

    &.features{
        display: flex;
        flex-wrap:wrap;
        justify-content: center;
        padding-bottom: 6rem;
        h4{flex: 0 1 100%}
    }
    .card{
        flex: 0 1 25%;
        padding: 2rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        transition: all 0.2s ease;
        border-width: 0 0 1px 1px;
        border-style: solid;
        border-color: #dcdcdc;
        background: #fff;
        &:hover{
            transform: scale(1.1);
            background: #fff;
            border-color:transparent;
            box-shadow: 0 0 25px rgba(0,0,0,0.2);
            z-index: 2;
        }
        &::before{display: none}
        &:active{transform: scale(1);}
        &:nth-of-type(4n+1){border-width: 0 0 1px 0}
        &:nth-last-of-type(-n+4){border-bottom-width: 0px}
        img{max-width: 6rem;max-height: 6rem;}
        strong{font-weight: normal; font-size: 16px; color: #4a4a4a}
        &:last-of-type strong{font-size: 3rem;font-weight: 300}

        @media(max-width: $break){
            flex: 1 1 30%;
            &:nth-of-type(4n+1){border-width: 0 0 1px 1px;}
            &:nth-of-type(3n+1){border-width: 0 0 1px 0}
            &:nth-last-of-type(-n+4){border-bottom-width: 1px}
            &:nth-last-of-type(-n+3){border-bottom-width: 0px}
        }
    }

    &.established{
        h4{font-family: $font-serif;font-weight: bold;@include font-sizer(50px, 30px);text-shadow: none;margin:0;}
        h4 span{
            @include font-sizer(60px, 40px);
            font-weight: bold;
            text-shadow: 1px 2px #fe7a64;
            letter-spacing: 5px;
            margin-left: 15px;
        }
        @media(max-width: $break){
            h4, p{
                text-shadow: 1px 1px 3px rgba(0,0,0,0.3);
            }
            .inner-row{position: relative}
        }
    }

    &.philosophy{
        .inner-row{flex-wrap: wrap; padding: 5rem 0;}
        h4{flex: 0 1 100%;margin: 2rem 0}
        a::before{background-color: $blue;}a:hover{color:#fff}
    }

    @media (max-width: $break){
        &.titles{display: none;}
    }
}

#services{
    background: #fff;
    padding-bottom: 8rem;
    .service-list .inner-row{
        color:#4a4a4a;
        display: block;
        align-items: unset;
        justify-content: unset;
        padding-top: 4rem;
        div.anchor {
            display: block;
            position: relative;
            top: -50px;
            visibility: hidden;
            height: 0;
            margin: 0;
        }
        div.anchor:target+div{
            background: #fff;
            box-shadow: 0 0 30px rgba(0,0,0,0.2);
            border-radius: 10px;
            padding: 4rem 2rem;
            @media(max-width: $break){
                padding: 0;
                box-shadow: none;
                border-left: 4px #fe7a64 solid;
                border-radius: 0px;
            }
        }
    }

    .service-list .inner-row > div{
        display: flex;
        align-items: flex-start;
        margin-top: 4rem;
        max-width: 80rem;
        width: 100%;
        img{max-width: 7rem;margin-right: 2rem;}
        strong{@include font-sizer(20px, 18px);color:#000}
        ul{margin: 1.5rem 0 0 0;}
        li{margin-bottom: 2rem;color:#4a4a4a;}
        @media(max-width: $break){
            ul{padding-right: 9rem;}
            img{margin-left: 2rem;}
        }
    }
}

#areas {
    background: #fff;
    h1{font-family: $font-serif;font-weight: 300;@include font-sizer(40px, 30px);}
    .first{
        box-shadow: 0 5px 15px rgba(0,0,0,0.3);
        z-index: 1;
        position: relative;
    }
    .first .inner-row{
        flex-direction: column;
        padding: 8rem 0;
        h1{margin-bottom: 1rem;}
    }
    .inner-row{color:#4a4a4a;}
    .areas{
        background: url('/images/map_huge_2.jpg') center / cover no-repeat;
        background-attachment: fixed;
        padding: 4rem 0;
    }
    ul{display: flex;flex-wrap: wrap;justify-content: center}
    ul li{
        flex: 1 1 20%;
        background: #fff;
        padding: 2rem;
        box-shadow: 0 0 2px rgba(0,0,0,0.3);
        border-radius: 5px;
        margin: 1rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media(max-width: $break){
        .first p{max-width: 300px}
        ul li{flex: 1 1 45%}
        ul{padding: 0 10px}
    }
}

#philosophy{
    background: linear-gradient(#242430,#080835);
    .inner-row{
        flex-wrap: wrap;
        text-align: center;
        padding: 10rem 0 15rem 0;
    }
    h1{
        width: 100%;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 1.5px;
        margin-bottom: 3rem;
        font-family: $font-serif;
    }
    p{
        max-width: 60rem;
        width: 100%;
        padding-bottom: 2rem;
        line-height: 1.5em;
        @include font-sizer(20px, 18px);
    }
}

#luna-vector{
    width: 600px;
    position: absolute;
    bottom: -82px;
    right: -80px;
    filter: saturate(100%);
    transition: filter 1s ease;
    &.not-in-view{
        filter: saturate(0%);
    }
    @media (max-width: $break){
        width: 100%;
        right: 0;
        transform: translateY(20%);
        bottom: 0;
        opacity: 0.5;
        transition: filter 1s 0.4s ease;
    }
}

#used-classes{display: none;}

.electrician-vector{
    height: 100%;
    margin: 0 0 0 auto;
    height: calc(100% + 7px);
    transform: translateY(-7px);
}

.lhs, .rhs{height: 100%;flex:0 1 50%;position: relative;}

footer{
    color: #fff;
    text-align: center;
    background: #20202b;
    padding: 20px;
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
    z-index: 0;
    font-size: 14px;
    a{font-size: 10px;display:block;color:#fff;}
}