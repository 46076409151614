@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/subset-RobotoSlab-Light.woff2') format('woff2'),
      url('./fonts/subset-RobotoSlab-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: optional;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('./fonts/subset-RobotoSlab-Bold.woff2') format('woff2'),
      url('./fonts/subset-RobotoSlab-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: optional;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $max-font-size;
      font-size: calc(#{$min-font-size});
      @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}

@mixin font-sizer($max, $min){
  @include fluid-type(320px, 1600px, $min, $max);
}


$font-sans: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-serif: "Roboto Slab", Avenir, "Lucida Bright", "Times New Roman", serif;

//! set font variables
//$font-heading: 'Libre-Baskerville-Reg', Fallback, 'Georgia', serif;
$font-body: sans-serif;


html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
  @include font-sizer(10px, 5px);
}

body {
  font-family: $font-sans;
}
